import { NavItem, Nav } from 'reactstrap';
import { useLocation, Link } from "react-router-dom";
import './Sidebar.css'

function Sidebar({tenant, branch}) {
    const location = useLocation();
    return (
        <div className="vms-sidebar">
            <Nav vertical>
                <NavItem className={location.pathname===`/${tenant}/${branch}/admin` ? "active" : ""}>
                    <Link className="nav-link" to={`/${tenant}/${branch}/admin`}><i className="fa fa-line-chart" aria-hidden="true"></i><label>&nbsp;&nbsp;&nbsp;Overview</label></Link>
                </NavItem>
                <NavItem className={location.pathname===`/${tenant}/${branch}/admin/tickets` ? "active" : ""}>
                    <Link className="nav-link" to={`/${tenant}/${branch}/admin/tickets`}><i className="fa fa-users" aria-hidden="true"></i><label>&nbsp;&nbsp;&nbsp;Tickets</label></Link>
                </NavItem>
                <NavItem className={location.pathname===`/${tenant}/${branch}/admin/agents` ? "active" : ""}>
                    <Link className="nav-link" to={`/${tenant}/${branch}/admin/agents`}><i className="fa fa-user-circle" aria-hidden="true"></i><label>&nbsp;&nbsp;&nbsp;Agents</label></Link>
                </NavItem>
                <NavItem className={location.pathname===`/${tenant}/${branch}/admin/languages` ? "active" : ""}>
                    <Link className="nav-link" to={`/${tenant}/${branch}/admin/languages`}><i className="fa fa-language" aria-hidden="true"></i><label>&nbsp;&nbsp;&nbsp;Languages</label></Link>
                </NavItem>
                <NavItem className={location.pathname===`/${tenant}/${branch}/admin/locations` ? "active" : ""}>
                    <Link className="nav-link" to={`/${tenant}/${branch}/admin/locations`}><i className="fa fa-map-marker" aria-hidden="true"></i><label>&nbsp;&nbsp;&nbsp;Locations</label></Link>
                </NavItem>
                <NavItem className={location.pathname===`/${tenant}/${branch}/admin/branches` ? "active" : ""}>
                    <Link className="nav-link" to={`/${tenant}/${branch}/admin/branches`}><i className="fa fa-code-fork" aria-hidden="true"></i><label>&nbsp;&nbsp;&nbsp;Branches</label></Link>
                </NavItem>
                <NavItem className={location.pathname===`/${tenant}/${branch}/admin/reasons` ? "active" : ""}>
                    <Link className="nav-link" to={`/${tenant}/${branch}/admin/reasons`}><i className="fa fa-building-o" aria-hidden="true"></i><label>&nbsp;&nbsp;&nbsp;Reasons</label></Link>
                </NavItem>
                <NavItem className={location.pathname===`/${tenant}/${branch}/admin/settings` ? "active" : ""}>
                    <Link className="nav-link" to={`/${tenant}/${branch}/admin/settings`}><i className="fa fa-cog" aria-hidden="true"></i><label>&nbsp;&nbsp;&nbsp;Settings</label></Link>
                </NavItem>
            </Nav>
        </div>
    )
}

export default Sidebar
