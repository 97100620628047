import React, {useEffect} from 'react'

function Settings({setActivePath}) {

    useEffect(()=>{
        setActivePath("Tickets")
    }, [])
    return (
        <div>
        </div>
    )
}

export default Settings
