import React, { createContext, useState, useEffect } from 'react';

export const DeviceContext = createContext();

export const DeviceProvider = ({ children }) => {
    const [deviceType, setDeviceType] = useState('desktop');

    const handleResize = () => {
        const width = window.innerWidth;
        if (width <= 760) {
            setDeviceType('mobile');
        } else if (width > 760 && width <= 1024) {
            setDeviceType('tablet');
        } else {
            setDeviceType('desktop');
        }
    };

    useEffect(() => {
        handleResize(); // Set initial device type
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <DeviceContext.Provider value={{ deviceType }}>
            {children}
        </DeviceContext.Provider>
    );
};
