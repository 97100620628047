import { ApolloClient, InMemoryCache } from '@apollo/client';
import { createHttpLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { getBaseURL } from './functions';

let client;
const BUGGY_URL = getBaseURL() + "/mobileql"
const createApolloClient = (uri) => {
  const httpLink = createHttpLink({
    uri: uri,
  });

  const authLink = setContext((_, { headers }) => {
    const token = localStorage.getItem('buggy_vms_user');
    if (token) {
      return {
        headers: {
          ...headers,
          authorization: `jwt ${token}`,
        },
      };
    } else {
      return headers;
    }
  });

  return new ApolloClient({
    link: authLink.concat(httpLink),
    cache: new InMemoryCache(),
  });
};

// Initialize the client with the default URI
client = createApolloClient(BUGGY_URL);

export { client, createApolloClient };
