import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Modal, ModalBody, Button, Row, Col, ModalHeader, ModalFooter, Input, FormGroup, Label, Alert } from 'reactstrap'
import { collection, getDocs, query } from "firebase/firestore"
import { db, addPrefix } from '../firebase'
import { createApolloClient } from '../ApolloClient'
import { getBaseURL } from '../functions'

function TenantBranchSelectionModal({ open, handleClose, handleSetParam }) {
    const [branch, setBranch] = useState("")
    const [allBranches, setAllBranches] = useState([])
    const [allTenants, setAllTenants] = useState([])
    const [tenant, setTenant] = useState(Object.values(useParams())[0])
    const [error, setError] = useState("")
    useEffect(() => {
        loadTenants()
    }, [])
    useEffect(() => {
        if(tenant.includes("/"))
            setTenant(tenant.split("/")[1])
        loadBranches()
    }, [tenant])
    const loadBranches = async () => {
        const querySnapshot = await getDocs(query(collection(db, addPrefix("branches"))))
        setAllBranches(querySnapshot.docs.map((doc) => ({ ...doc.data(), firebaseId: doc.id })).filter(doc => doc.tenant === tenant))
    }

    const loadTenants = async () => {
        const querySnapshot = await getDocs(query(collection(db, addPrefix("tenants"))))
        setAllTenants(querySnapshot.docs.map((doc) => ({ ...doc.data(), firebaseId: doc.id })))
    }

    const handleBranchSelection = () => {
        if(tenant && branch){
            handleSetParam(tenant,branch)
            if (!window.location.hostname.includes("localhost") && !window.location.hostname.includes("staging") )
                createApolloClient(getBaseURL(tenant))
            handleClose()
        }else{
            setError("Please select tenant and branch")
        }

    }
    return (
        <Modal isOpen={open} className="vms-ticket-modal" style={{ maxWidth: "50%" }}>
            <ModalHeader>Set Tenant/Branch</ModalHeader>
            <ModalBody className="px-4">
                {error && <Row><Col xs={12}><Alert color="danger">{error}</Alert></Col></Row>}
                <Row>
                    <Col>
                        <FormGroup>
                            <Label for="branch">Tenant *</Label>
                            <Input type="select" name="branch" className='text-capitalize' value={tenant} onChange={(e) => { setTenant(e.target.value) }} >
                                <option value="">-- Select Tenant --</option>
                                {allTenants && allTenants?.map(tenant => <option className='text-capitalize' key={tenant.name} value={tenant.name}>{tenant.name}</option>)}
                            </Input>
                        </FormGroup>
                        <br/>
                        <FormGroup>
                            <Label for="branch">Branch *</Label>
                            <Input type="select" name="branch" onChange={(e) => { setBranch(e.target.value) }} >
                                <option value="">-- Select branch --</option>
                                {allBranches && allBranches?.map(branch => <option key={branch.customId} value={branch.customId}>{branch.name}</option>)}
                            </Input>
                        </FormGroup>
                    </Col>
                </Row>
            </ModalBody>
            <ModalFooter>
                <Button className="btn" color="primary" onClick={handleBranchSelection}>Set Tenant/Branch</Button>
            </ModalFooter>
        </Modal>
    )
}

export default TenantBranchSelectionModal
