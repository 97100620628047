import { useState, useContext } from 'react'
import { Container, Row, Col } from 'reactstrap';
import { useParams } from 'react-router-dom';
import TicketModal from './TicketModal';
import QueueContent from './QueueContent';
import moment from 'moment';
import Header from '../Header/Header';
import {DeviceContext} from '../Context/DeviceContext';

function QueueContainer({ currentUser, setCurrentUser, height, activeDrivers, newDrivers, allAgents, locations, allLanguages, handleLogout, handleUserUpdate,userUpdateError, handleCloseErrorModel}) {
    const tickets = activeDrivers.filter(driver => driver.status == "waiting" && !driver.removed)
    const [showModal, setShowModal] = useState("")
    const branch = useParams().branch
    const isAgent = currentUser && currentUser.pk ? true : false
    const [isSidebarVisible, setSidebarVisible] = useState(false);
    const { deviceType } = useContext(DeviceContext);

    const getColumnSize = () => {
        switch (deviceType) {
            case 'mobile':
                return { sidebar: 12, content: 12 };
            case 'tablet':
                return { sidebar: 4, content: 8 };
            case 'desktop':
                return { sidebar: 3, content: 9 };
            default:
                return { sidebar: 4, content: 8 };
        }
    };

    const colSize = getColumnSize();

    const toggleSidebar = () => {
        setSidebarVisible(!isSidebarVisible);
    };

    const sendMessage = (to, body) => {
        fetch(process.env.REACT_APP_MESSAGE_API_URL, {
            method: 'POST',
            headers: {Accept: 'application/json', 'Content-Type': 'application/json'},
            body: JSON.stringify({
                to: to,
                from: '+18885248449',
                body: body,
                Subject: 'BUGGY TLC'
            })
        }).then((response) => {
            if (response && response.status && response.status === 200) return true
            else throw ("An error occured while sending message to driver. Please try again!")
        }).catch(error => {
                throw ("An error occured while sending message to driver. Please try again!")
        });
    }
    return (
        <Container fluid>
            <Row>
                <Col className='p-0'>
                    <Header currentUser={currentUser} allLocations={locations} allLanguages={allLanguages} activeDrivers={activeDrivers} allAgents={allAgents} handleLogout={handleLogout} handleUserUpdate={handleUserUpdate} userUpdateError={userUpdateError} handleCloseErrorModel={handleCloseErrorModel}/>
                </Col>
            </Row>
            <Row className="vms-main-wrapper">
                <Col xs={colSize.sidebar}  className={`vms-queue-sidebar ${isSidebarVisible ? 'show-sidebar d-block' : 'd-none d-md-block'}`} >
                    <Row className="vms-queue-sidebar-title">
                        <Col>VISITOR'S QUEUE</Col>
                    </Row>
                    {tickets?.map((driver, i) =>
                        <Row className="vms-queue-item" onClick={() => setShowModal("showDriver" + driver.pk)} key={i}>
                            {showModal === "showDriver" + driver.pk && <TicketModal handleClose={() => setShowModal("")} open={showModal === "showDriver" + driver.pk} driver={driver} allAgents={allAgents} currentUser={currentUser} handleMessage={sendMessage} tickets={activeDrivers} />}
                            <Col xs={isAgent ? 2 : 3} className="text-center pt-2">
                                <i className="fa fa-user-circle-o" aria-hidden="true" style={{fontSize: "3rem", color: "#393e5c"}} />
                            </Col>
                            <Col xs={isAgent ? 10 : 9}>
                                <Row>
                                    <Col>
                                        <h4 className="limit-text mb-3">{driver.name}</h4>
                                        <p className="vms-queue-item-date">{moment(driver.dateAdded.toDate()).format('lll')}</p>
                                        {isAgent && <p className="vms-queue-item-date">Balance: <b>{"$" + driver.balance}</b></p>}
                                    </Col>
                                    {isAgent && 
                                        <Col>
                                            <h3 className="limit-text mb-3" style={{color: "#393e5c"}}><i className="fa fa-language" aria-hidden="true" /> {driver.language}</h3>
                                            <p className="vms-queue-item-moves">Back To Queue: <b>{driver.backToQueue ? driver.backToQueue.length : 0}</b></p>
                                            <p className="vms-queue-item-moves">Transferred: <b>{driver.transfers ? driver.transfers.length : 0}</b></p>
                                        </Col>
                                    }
                                </Row>
                                {isAgent && driver && driver.department &&  <Row>
                                    <Col>
                                        <p className="vms-queue-item-reasons">
                                        <i className="fa fa-hand-o-right" /> {driver.department}
                                        </p>
                                    </Col>
                                </Row>}
                            </Col>
                            {isAgent && 
                                <Col className="pb-3">
                                    <small><b>Notes:: </b>{driver.notes}</small>
                                </Col>
                            }
                        </Row>
                    )}
                    {tickets?.length === 0 && <p className="text-center pt-5"><b>No Driver in Queue!</b></p>}
                    {(deviceType === "mobile" || deviceType === "tablet")&& <a href="javascript:void(0)" onClick={toggleSidebar}><i className="fa float-right fa-2x fa-arrow-circle-o-left" aria-hidden="true" /></a>}
            </Col>
            <Col xs={colSize.content} className="vms-main-content-container">
                {!isSidebarVisible && (
                    <a href="javascript:void(0)" onClick={toggleSidebar} className="toggle-sidebar-icon d-md-none">
                        <i className="fa fa-2x fa-arrow-circle-o-right" aria-hidden="true"  />
                    </a>
                )}
                <QueueContent currentUser={currentUser} setCurrentUser={setCurrentUser} handleMessage={sendMessage} allAgents={allAgents} activeDrivers={activeDrivers} newDrivers={newDrivers} locations={locations.filter(loc => loc.customBranchId === branch).map(loc => loc.name)} />
            </Col>
        </Row>
        </Container>
    )
}

export default QueueContainer
