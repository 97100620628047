import { useState, useEffect } from 'react'
import { Modal, ModalBody, Button, Row, Col, Badge, ModalHeader, ModalFooter, Input, FormFeedback, FormGroup, Label, FormText } from 'reactstrap';
import gql from 'graphql-tag';
import { useMutation } from '@apollo/client';
import parsePhoneNumber from 'libphonenumber-js'
import Languages from "./Languages.json"

const CreateCustomerLead = gql`mutation createCustomerLead($input: CreateLeadMutationInput!) {
    createCustomerLead(input:$input) {
        ok
        errors {
            field
            messages
        }
    }
}`;


function RegisterDriverModal({ open, handleClose, input, isPhone, handleNext, driverData, setSearchTerm, refetchDriver, branch }) {
    const [error, setError] = useState("")
    const [loading, setLoading] = useState(false)
    const [tlcValid, setTlcValid] = useState(false)
    const [isSubmitted, setIsSubmitted] = useState(false)
    const [driver, setDriver] = useState({
        fullName: "",
        phone: isPhone ? input : "",
        email: isPhone ? "" : input,
        branchCustomId:branch,
        referredBy:"",
        documents: [],
        preferredLanguage: ""
    })
    const referralOptions = ["Google", "Instagram", "Youtube", "Facebook", "Uber", "Friend", "Others"]

    useEffect(async () => {
        if (driver.tlcLicenseId) {
            await fetch("https://data.cityofnewyork.us/resource/xjfq-wh2d.json?license_number=" + driver.tlcLicense, {
                method: 'GET',
                data: { "$limit": 1, "$$app_token": "5WBDkAQLj244SKLuDJmXDVDhT" }
            }).then(async (response) => await response.json()).then((data) => {
                if (data && data[0] && data[0].name) setTlcValid(true)
                else setTlcValid(false)
            });
        } else {
            setTlcValid(false)
        }
    }, [driver.tlcLicenseId])

    const isValid = (name, value) => {
        if (name === "phone") {
            if (value.length < 10) return false
            value = value.includes("+1") ? value : ("+1" + value)
            if (parsePhoneNumber(value)?.isValid()) return true
            else return false
        } else if (name === "email") {
            let re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return re.test(String(value).toLowerCase());
        } else if (name === "zip") {
            let re = /(^\d{5}$)|(^\d{5}-\d{4}$)/;
            return re.test(String(value).toLowerCase());
        }
        return value != ""
    }

    const handleRegisterDriver = async () => {
        setIsSubmitted(true);
        setLoading(true);
        let tmp = true;
        let errorItem = '';
        await Object.keys(driver)?.forEach(item => {
            if (item === "tlcLicenseId") tmp = tlcValid
            else tmp = isValid(item, driver[item])
            errorItem = item
            if (!tmp) return
            tmp = true;
        });
        if (tmp) await registerDriver()
        else setError(`Provide ${errorItem} has invalid value. Please try again with valid value!`);
        setLoading(false);
    }

    const [registerDriver] = useMutation(CreateCustomerLead, {
        variables: {
            input:{
                fullName: driver.fullName,
                phone: driver.phone.includes("+1") ? driver.phone : ("+1" + driver.phone),
                email: driver.email,
                isTlc: ["BROOKLYN_NY", "FASTTRACK_BROOKLYN"].includes(branch) ? true : false,
                branchCustomId:driver.branchCustomId,
                documents: driver.documents,
                dmvLicenseId: driver.dmvLicenseId,
                tlcLicenseId: driver.tlcLicenseId,
                source:"Visitor Management System"
            }
        },
        fetchPolicy: 'no-cache',
        onError: (error) => { setError("An error occured. Please contact admin." + error); setLoading(false); },
        onCompleted: async (response) => {
            if (response.createCustomerLead.errors == null && response.createCustomerLead.ok) {
                await setSearchTerm(isPhone ? driver.phone : driver.email)
                await refetchDriver()
                handleClose()
            } else {
                setError(response.createCustomerLead.errors[0].messages[0])
                setLoading(false)
            }
        }
    })

    const updateFile = (e) => {
        let base64Img = e.target.files[0]
        let filename = e.target.name
        let documents = [...driver.documents]
        let newDocument = {
            documentType: filename,
            base64String: null
        }
        var reader = new FileReader()
        reader.readAsDataURL(base64Img)
        reader.onload = function (e) {
            let imageInBase64 = reader.result
            imageInBase64 = imageInBase64.substring(imageInBase64.indexOf(',') + 1)
            newDocument.base64String = imageInBase64
            documents.push(newDocument)
            setDriver({ ...driver, documents })
        }
    }


    useEffect(() => {
        if (driverData && driverData.id) handleNext()
    }, [driverData])

    return (
        <Modal isOpen={open} toggle={handleClose} className="vms-ticket-modal" style={{ maxWidth: "70%" }}>
            <ModalHeader>Driver Registration</ModalHeader>
            <ModalBody className="px-4">
                <Row>
                    <Col>
                        <FormGroup>
                            <Label for="fullName">Full Name *</Label>
                            <Input name="fullName" value={driver.fullName} onChange={(e) => { setDriver({ ...driver, fullName: e.target.value }) }} valid={isValid("fullName", driver.fullName)} invalid={(isSubmitted || driver.fullName) && !isValid("fullName", driver.fullName)} />
                            <FormFeedback>Provided full name is invalid!</FormFeedback>
                        </FormGroup>
                    </Col>
                    <Col>
                        <FormGroup>
                            <Label for="phone">Phone *</Label>
                            <Input name="phone" value={driver.phone} onChange={(e) => { setDriver({ ...driver, phone: e.target.value }) }} valid={isValid("phone", driver.phone)} invalid={(isSubmitted || driver.phone) && !isValid("phone", driver.phone)} />
                            <FormFeedback>Provided phone is invalid!</FormFeedback>
                            <FormText>Example: +13473346313</FormText>
                        </FormGroup>
                    </Col>
                    <Col>
                        <FormGroup>
                            <Label for="email">Email *</Label>
                            <Input name="email" value={driver.email} onChange={(e) => { setDriver({ ...driver, email: e.target.value }) }} valid={isValid("email", driver.email)} invalid={(isSubmitted || driver.email) && !isValid("email", driver.email)} />
                            <FormFeedback>Provided email is invalid!</FormFeedback>
                            <FormText>Example: john_doe@gmail.com</FormText>
                        </FormGroup>
                    </Col>
                </Row>
                <br />
                <Row>
                    <Col>
                        <FormGroup>
                            <Label for="preferredLanguage">Preferred Language*</Label>
                            <Input type="select" name="referredBy" value={driver.preferredLanguage} onChange={(e) => { setDriver({ ...driver, preferredLanguage: e.target.value }) }} valid={isValid("preferredLanguage", driver.preferredLanguage)} invalid={(isSubmitted || driver.preferredLanguage) && !isValid("preferredLangiage", driver.preferredLanguage)} >
                                <option value="" disabled>-- Select Preferred Language --</option>
                                {Object.keys(Languages).map(language => <option value={language}>{Languages[language].name + " - " + Languages[language].nativeName}</option>)}
                            </Input>
                            <FormFeedback>Provided Language is invalid!</FormFeedback>
                        </FormGroup>
                    </Col>
                    <Col>
                        <FormGroup>
                            <Label for="referredBy">Referred From *</Label>
                            <Input type="select" name="referredBy" value={driver.referredBy} onChange={(e) => { setDriver({ ...driver, referredBy: e.target.value }) }} valid={isValid("referredBy", driver.referredBy)} invalid={(isSubmitted || driver.referredBy) && !isValid("referredBy", driver.referredBy)} >
                                <option value="" disabled>-- Select referredBy --</option>
                                {referralOptions.map(referredBy => <option value={referredBy}>{referredBy}</option>)}
                            </Input>
                            <FormFeedback>Provided referral is invalid!</FormFeedback>
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <FormGroup>
                            <Label for="Driver License">Upload Driver License (Optional)</Label><br/>
                            <Input type="file" name="Driver License" id="Driver License" onChange={updateFile} /><br/>
                            <small color="muted">Accepted files include jpg,jpeg and pngs</small><br/>
                        </FormGroup>
                    </Col>
                    {(branch === "BROOKLYN_NY" || branch === "FASTTRACK_BROOKLYN") &&
                        <Col>
                            <FormGroup>
                                <Label for="TLC">Upload TLC License (Optional)</Label><br/>
                                <Input type="file" name="TLC" id="TLC" onChange={updateFile} /><br/>
                                <small color="muted">Accepted files include jpg,jpeg and pngs</small><br/>
                            </FormGroup>
                        </Col>}
                </Row>
            </ModalBody>
            <ModalFooter>
                {error && <Badge color="danger" style={{ whiteSpace: "pre-line" }}>{error}</Badge>}
                <Button className="btn" color="secondary" onClick={() => handleClose()}>Cancel</Button>
                {loading ? <span>Loading...</span> : <Button className="btn" color="primary" onClick={() => { handleRegisterDriver(); }}>Register</Button>}
            </ModalFooter>
        </Modal>
    )
}

export default RegisterDriverModal
