import { useState, useEffect } from 'react'
import { useParams, useHistory, Link } from 'react-router-dom'
import { collection, getDocs, query, updateDoc, doc } from "firebase/firestore"
import { db, addPrefix } from '../firebase'
import { Nav, Navbar, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, NavLink} from 'reactstrap'
import agentPlaceholder from "../assets/agent.png"
import adminPlaceholder from '../assets/placeholder.png'
import './Header.css'
import ErrorModal from '../Components/ErrorModal'
import VGMLogo from "../assets/VGMLogo.png"
import TenantBranchSelectionModal from '../Checkin/TenantBranchSelectionModal'

const Hamburger = () => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="52"
      height="24"
      viewBox="0 0 52 24"
    >
      <g id="Group_9" data-name="Group 9" transform="translate(-294 -47)">
        <rect
          id="Rectangle_3"
          data-name="Rectangle 3"
          width="42"
          height="4"
          rx="2"
          transform="translate(304 47)"
          fill="#574c4c"
        />
        <rect
          id="Rectangle_5"
          data-name="Rectangle 5"
          width="42"
          height="4"
          rx="2"
          transform="translate(304 67)"
          fill="#574c4c"
        />
        <rect
          id="Rectangle_4"
          data-name="Rectangle 4"
          width="42"
          height="4"
          rx="2"
          transform="translate(304 57)"
          fill="#574c4c"
        />
      </g>
    </svg>
  );

function Header({ currentUser, allLocations, activeDrivers, allLanguages, handleLogout, handleUserUpdate, userUpdateError, handleCloseErrorModel }) {
    const [branch, setBranch] = useState(Object.values(useParams())[1])
    const [tenant, setTenant] = useState(Object.values(useParams())[0])
    const qr = Object.values(useParams())[2]
    let history = useHistory()
    const [allBranches, setAllBranches] = useState([])
    const [allTenants, setAllTenants] = useState([])
    const pathName = window.location.pathname
    const [error, setError] = useState([])
    const [openModal, setOpenModal] = useState("")
    const [showNavbar, setShowNavbar] = useState(false);

    const   handleShowNavbar = () => {
        setShowNavbar(!showNavbar);
    };
    const isUserBusy = async (agentId) => {
        return activeDrivers.filter(driver => driver.status == "assigned" && driver.agent == Number(agentId)).length > 0
    }
    useEffect(() => {
        loadBranches()
        loadTenants()
        if (branch && tenant){
            return true
        }else{
            setOpenModal("branchSelection")
        }
    }, [])

    const handleLoginAsAdmin = async () => {
        if (await isUserBusy(currentUser.pk) === true) {
            setError("Please complete your assigned task in order to switch to admin.")
        }
        else {
            try {
                if (currentUser.pk) {
                    await updateDoc(doc(db, addPrefix("users"), String(currentUser.pk)), {
                        status: "inactive",
                        "isAgent": false,
                    })
                }
                window.location.reload(false)
            } catch (err) {
                setError("Error: Unable to login as admin!")
            }
        }
    }
    const handleLoginAsAgent = async () => {
        try {
            if (currentUser.pk) {
                await updateDoc(doc(db, addPrefix("users"), String(currentUser.pk)), {
                    "status": "active",
                    "isAgent": true,
                })
            }
            window.location.reload()
        } catch (err) {
            setError("Error: Unable to login as agent!")
        }
    }
    const loadBranches = async () => {
        const querySnapshot = await getDocs(query(collection(db, addPrefix("branches"))))
        setAllBranches(querySnapshot.docs.map((doc) => ({ ...doc.data(), firebaseId: doc.id })).filter(doc => doc.tenant === tenant))
    }
    const loadTenants = async () => {
        const querySnapshot = await getDocs(query(collection(db, addPrefix("tenants"))))
        setAllTenants(querySnapshot.docs.map((doc) => ({ ...doc.data(), firebaseId: doc.id })))
    }
    const setParam = (tenant,branch) => {
        const segments = window.location.href.split("/");
        const sectionName = segments[5];
        const sections = ["checkin", "queue", "admin"];
        const pathName = sections.includes(sectionName) 
        ? `/${tenant}/${branch}/${sectionName}` 
        : `/${tenant}/${branch}`;
        history.push(pathName)
        window.location.reload()
    }
    useEffect(() => {
        if (!allBranches || allBranches.length === 0 || !allTenants || allTenants.length === 0) {
            return true
        } else if (
            branch &&
            allBranches.find(item => item.customId.toLowerCase() === branch.toLowerCase()) &&
            tenant &&
            allTenants.find(item => item.name.toLowerCase() === tenant.toLowerCase())
        ) {
            setOpenModal("");
        } else {
            setOpenModal("branchSelection");
        }
    }, [allTenants, allBranches, branch, tenant]);
    return (
        <>
            <Navbar light expand="xs" className="vms-header">
                <Nav className="mr-auto d-flex flex-row justify-content-between" navbar>
                    {openModal === "branchSelection" && <TenantBranchSelectionModal open={openModal === "branchSelection"} handleClose={() => setOpenModal(" ")} handleSetParam={(tenant,branch) => setParam(tenant,branch)} />}
                    <div className="text-center align-self-center">
                        <Link className="logo" to={`/${tenant}/${branch}/`}><img src={VGMLogo} width={60} /></Link>
                        <Link className={"navItem "+(pathName.includes("checkin") ? "active" : "inactive")} to={`/${tenant}/${branch}/checkin`}>CHECKIN</Link> &nbsp;
                        <Link className={"navItem "+(pathName.includes("admin") ? "active" : "inactive")} to={`/${tenant}/${branch}/admin`}>{currentUser ? !currentUser.isAgent ? "ADMIN" : "AGENT" : "ADMIN/AGENT"}</Link>&nbsp;
                        <Link className={"navItem "+(pathName.includes("queue") ? "active" : "inactive")} to={`/${tenant}/${branch}/queue`}>QUEUE</Link>&nbsp;
                    </div>
                    {!qr && <div className="menu-icon" onClick={handleShowNavbar}>
                        <Hamburger />
                    </div>}
                    <div className={`nav-elements  ${showNavbar && "active"}`}>
                        <ul>
                            { showNavbar &&
                                <>
                                <li>
                                    <NavLink href={`/${tenant}/${branch}/checkin`} className={pathName.includes("checkin") ? "active" : "inactive"}>CHECKIN</NavLink>
                                </li>
                                <li>
                                    <NavLink href={`/${tenant}/${branch}/admin`} className={pathName.includes("admin") ? "active" : "inactive"}>{currentUser ? !currentUser.isAgent ? "ADMIN" : "AGENT" : "ADMIN/AGENT"}</NavLink>
                                </li>
                                <li>
                                    <NavLink href={`/${tenant}/${branch}/queue`} className={pathName.includes("queue") ? "active" : "inactive"}>QUEUE</NavLink>
                                </li>
                                </>
                            }
                            {currentUser && <>
                                <UncontrolledDropdown nav inNavbar>
                                    <DropdownToggle nav caret>
                                        <span className={"vms-avatar " + (currentUser.status === "active" ? "border-green" : "")} style={{ width: "30px" }}>
                                            <img src={currentUser.isAgent ? agentPlaceholder : adminPlaceholder} className="w-100" alt="" />
                                        </span>&nbsp;&nbsp;{currentUser.username}
                                    </DropdownToggle>
                                    <DropdownMenu right>
                                        {(currentUser.groupNames && currentUser.groupNames.includes("VMS-Admin") && currentUser.isAgent) && <DropdownItem onClick={() => handleUserUpdate("status", currentUser.status === "active" ? "inactive" : "active", false)}><b>{currentUser.status === "active" ? "Inactive" : "Active"}</b></DropdownItem>}
                                        <DropdownItem divider />
                                        {(currentUser.groupNames && currentUser.groupNames.includes("VMS-Admin") && currentUser.isAgent) ? <DropdownItem onClick={() => handleLoginAsAdmin()}><b>Switch To Admin</b></DropdownItem> : <DropdownItem onClick={() => handleLoginAsAgent()}><b>Switch To Agent</b></DropdownItem>}
                                        <DropdownItem divider />
                                        <DropdownItem onClick={() => handleLogout()}><b>Logout</b></DropdownItem>
                                    </DropdownMenu>
                                </UncontrolledDropdown>
                                {userUpdateError && userUpdateError != "" && <ErrorModal open={!!error} handleClose={handleCloseErrorModel} message={userUpdateError} />}
                                {currentUser.isAgent &&
                                    <>
                                        <UncontrolledDropdown nav inNavbar>
                                            <DropdownToggle nav caret>
                                                {currentUser.location ? currentUser.location : <span className="text-danger">No Location Selected <i className="fa fa-exclamation-circle" /> </span>}&nbsp;&nbsp;&nbsp;
                                            </DropdownToggle>
                                            <DropdownMenu>
                                                {allLocations?.filter(loc => loc.customBranchId === branch).map(loc =>
                                                    <><DropdownItem onClick={() => handleUserUpdate("location", loc.name)}><b>{loc.name}</b></DropdownItem><DropdownItem divider /></>
                                                )}
                                            </DropdownMenu>
                                        </UncontrolledDropdown>
                                        <UncontrolledDropdown nav inNavbar>
                                            <DropdownToggle nav caret>
                                                {currentUser.language ? currentUser.language : "No Language Selected"}&nbsp;&nbsp;&nbsp;
                                            </DropdownToggle>
                                            <DropdownMenu>
                                                {allLanguages?.map((lang, i) =>
                                                    <>
                                                        <DropdownItem key={i} onClick={() => handleUserUpdate("language", lang.name)}>
                                                            <b>{lang.name}</b>
                                                        </DropdownItem>
                                                        <DropdownItem divider />
                                                    </>
                                                )}

                                            </DropdownMenu>
                                        </UncontrolledDropdown>
                                    </>}
                        </>}
                        <UncontrolledDropdown nav inNavbar>
                            <DropdownToggle nav caret className='text-capitalize'>
                                {tenant && allTenants.find(item => item.name == tenant) ? allTenants.find(item => item.name == tenant).name : "Select Tenant"}
                            </DropdownToggle>
                            <DropdownMenu right>
                                {allTenants?.map(tenant => <DropdownItem className='text-capitalize' onClick={() => setParam(tenant.name, branch)}>{tenant.name}</DropdownItem>)}
                            </DropdownMenu>
                        </UncontrolledDropdown>
                        <UncontrolledDropdown nav inNavbar>
                            <DropdownToggle nav caret>
                                {branch && allBranches.find(item => item.customId == branch) ? allBranches.find(item => item.customId == branch).name : "Select Branch"}
                            </DropdownToggle>
                            <DropdownMenu right>
                                {allBranches?.map(branch => <DropdownItem onClick={() => setParam(tenant,branch.customId)}>{branch.name}</DropdownItem>)}
                            </DropdownMenu>
                        </UncontrolledDropdown>
                        </ul>
                    </div>
                </Nav>
            </Navbar>
        </>
    )
}

export default Header
