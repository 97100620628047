import { useState } from 'react'
import { updateDoc, doc } from "firebase/firestore";
import { db, addPrefix } from '../firebase';
import QueueContainer from './QueueContainer';
import './Agent.css'

function Agent({ currentUser, handleLogout, setCurrentUser, languages, locations, activeDrivers, allAgents }) {
  const allLocations = locations.filter(location => location.status == "active")
  const allLanguages = languages.filter(language => language.status == "active")
  const [error, setError] = useState([])

  const isUserBusy = async (agentId) => {
    return activeDrivers.filter(driver => driver.status == "assigned" && driver.agent == Number(agentId)).length > 0;
  }

  const handleUserUpdate = async (name, value) => {
    if (name !== "language" && await isUserBusy(currentUser.pk) === true) {
      setError(`It seems like you forgot to complete the assigned task. Before changing the location. Please complete your assigned task and try again.`)
      return
    }
    if (name === "location") {
      if (allAgents.filter(agent => agent.location == value).length>0) {
        setError(`This location is already reserved by ${allAgents.filter(agent => agent.location == value)[0].username}!`)
        return
      }
    }
    try {
      await updateDoc(doc(db, addPrefix("users"), String(currentUser.pk)), {
        [name]: value,
      });
      setCurrentUser({ ...currentUser, [name]: value })
      setError("")
    } catch (err) {
      setError("We are having trouble to update user in database!")
    }
  }

 
  return (<>
    <QueueContainer currentUser={currentUser} setCurrentUser={setCurrentUser} allAgents={allAgents} activeDrivers={activeDrivers} locations={allLocations} allLanguages={allLanguages} handleLogout={handleLogout} handleUserUpdate={handleUserUpdate} userUpdateError={error} handleCloseErrorModel={() => setError("")} />
  </>)
}

export default Agent
