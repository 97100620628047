import { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom';
import { BrowserRouter as Switch, Route } from "react-router-dom";
import { Row, Col, Input, Label, InputGroup, InputGroupAddon, InputGroupText, Button } from 'reactstrap';
import { collection, getDocs, query, where, orderBy } from "firebase/firestore";
import { db, addPrefix } from '../firebase';
import Sidebar from './Sidebar';
import Dashboard from './Dashboard';
import Tickets from './Tickets';
import Agents from './Agents';
import Languages from './Languages';
import Locations from './Locations';
import Departments from './Departments';
import Settings from './Settings';
import Branches from './Branches';
import DateRangePickerModal from './DateRangePickerModal';
import moment from 'moment';
import './Admin.css'
import Header from '../Header/Header';

function Admin({ currentUser, handleLogout, allAgents, activeDrivers, allLocations, allLanguages, allDepartments, loadLocations, loadLanguages, loadDepartments, allBranches, loadBranches }) {
  const [activePath, setActivePath] = useState("")
  const [allTickets, setAllTickets] = useState([])
  const [filteredDrivers, setFilteredDrivers] = useState()
  const [filteredAgents, setFilteredAgents] = useState()
  const [openModal, setOpenModal] = useState("")
  const branch = useParams().branch;
  const tenant = useParams().tenant;
  const [filters, setFilters] = useState({
    selection: {
        startDate: moment().startOf('week'),
        endDate: moment().endOf('week'),
        key: 'selection',
    },
    agent: null, reason: null, status: null, searchTerm: null, autoClosed: ""
  })

  //To get all Tickets
  const loadTickets = async () => {
    let querySet = query(collection(db, addPrefix("tickets")));
    if(branch){
      querySet = query(querySet, where("customBranchId", "==", branch))
    }
    if (filters.selection.startDate) querySet = query(querySet, where("dateAdded", ">=", new Date(filters.selection.startDate)))
    if (filters.selection.endDate) {
      let currentDate = new Date(filters.selection.endDate)
      currentDate.setDate(currentDate.getDate() + 1)
      querySet = query(querySet, where("dateAdded", "<=", currentDate))
    }
    const querySnapshot = await getDocs(query(querySet, orderBy("dateAdded", "desc")))
    setAllTickets(querySnapshot.docs.map(doc => ({ ...doc.data(), firebaseId: doc.id })))
  }

  useEffect(() => {
    loadTickets()
  }, [filters.selection])

  useEffect(() => {
    let drivers = allTickets
    let agents = allAgents

    if (filters && filters.agent) {
        agents = agents.filter(agent => agent.userPk == filters.agent)
        drivers = drivers.filter(driver => driver.agent == filters.agent)
    }
    if (filters && filters.reason) 
        drivers = drivers && drivers.filter(driver => driver.department == filters.reason)
    if (filters && filters.selection.startDate)
        drivers = drivers && drivers.filter(driver => moment(driver.dateAdded.toDate()).format(moment.HTML5_FMT.DATE) >= moment(filters.selection.startDate).format(moment.HTML5_FMT.DATE))
    if (filters && filters.selection.endDate)
        drivers = drivers && drivers.filter(driver => moment(driver.dateAdded.toDate()).format(moment.HTML5_FMT.DATE) <= moment(filters.selection.endDate).format(moment.HTML5_FMT.DATE))
    if (filters.status)
        drivers = drivers && drivers.filter(driver => driver.status == filters.status);
    if(filters.searchTerm) 
        drivers = drivers && drivers.filter(driver => (driver.name.toLowerCase().includes(filters.searchTerm.toLowerCase()) || driver.phone?.includes(filters.searchTerm) || driver.tlcLicense?.includes(filters.searchTerm)))        
    if (filters.language)
      drivers = drivers && drivers.filter(driver => driver.language == filters.language);
    if (filters && ["true", "false"].includes(filters.autoClosed)){
      drivers = drivers && drivers.filter(driver => driver.autoClosed == (filters.autoClosed === "true"? true:false))
    }
    
    setFilteredDrivers(drivers)
    setFilteredAgents(agents)
  }, [allTickets, allAgents, filters])

  return (<>
    <Row>
      <Col className='p-0'>
          <Header currentUser={currentUser} allLocations={allLocations} allLanguages={allLanguages} activeDrivers={activeDrivers} allAgents={allAgents} handleLogout={handleLogout} />
      </Col>
    </Row>
    <Switch>
      <Row className="no-gutters vms-main-wrapper" style={{ background: "#E4E8F4" }}>
          <Col md={1} lg={1} xl={2}>
              <Sidebar branch={branch} tenant={tenant} />
          </Col>
          <Col md={11} lg={11} xl={10}>
              <div className="vms-admin-container">
                  {activePath && (activePath === "Dashboard" || activePath === "Tickets") && <Row className="vms-filters-wrapper vms-dashboard-filter-wrapper no-gutters">
                      <Col>
                          <DateRangePickerModal isOpen={openModal === "dateRangePicker"} handleClose={()=>setOpenModal()} filters={filters} setFilters={setFilters}/>
                          <Label>Filter By Date Range</Label>
                          <br/>                    
                          <Button style={{borderRadius: "0"}} onClick={() => setOpenModal("dateRangePicker")}>
                            {moment(filters.selection.startDate).format(moment.HTML5_FMT.DATE) === moment().startOf('week').format(moment.HTML5_FMT.DATE) && moment(filters.selection.endDate).format(moment.HTML5_FMT.DATE) === moment().endOf('week').format(moment.HTML5_FMT.DATE) ? 
                              "Current Week" : (filters.selection.startDate || filters.selection.endDate) ? 
                              ((filters.selection.startDate ? moment(filters.selection?.startDate).format(moment.HTML5_FMT.DATE) : "--") + " - " + (filters.selection.endDate ? moment(filters.selection?.endDate).format(moment.HTML5_FMT.DATE) : "--")) : "Click to Select Range"
                            }
                          </Button>
                      </Col>
                      <Col>
                        {activePath === "Tickets" && <>
                          <Label>Filter By Language</Label>
                          <InputGroup>
                              <Input type="select" value={filters.language} onChange={(e) => setFilters({ ...filters, language: e.target.value })}>
                                  <option value="">-- Filter by language --</option>
                                  {allLanguages?.map(language => language.name).map(item => <option value={item}>{item}</option>)}
                              </Input>
                              <InputGroupAddon addonType="append">
                                  <InputGroupText><i className="fa fa-language" aria-hidden="true"></i></InputGroupText>
                              </InputGroupAddon>
                          </InputGroup>
                        </>}
                      </Col>
                      <Col>
                        {activePath === "Tickets" && <>
                          <Label>Filter By Auto Closed</Label>
                          <InputGroup>
                              <Input type="select" value={filters.sutoClosed} onChange={(e) => setFilters({ ...filters, autoClosed: e.target.value })}>
                                  <option value="">-- Filter by Auto Removed --</option>
                                  {["true", "false"].map(item => <option value={item}>{item}</option>)}
                              </Input>
                              <InputGroupAddon addonType="append">
                                  <InputGroupText><i className="fa fa-lock" aria-hidden="true"></i></InputGroupText>
                              </InputGroupAddon>
                          </InputGroup>
                        </>}
                      </Col>
                      <Col>
                        {activePath === "Tickets" && <>
                          <Label>Search</Label>
                          <InputGroup>
                              <Input type="text" value={filters.searchTerm} onChange={(e) => setFilters({ ...filters, searchTerm: e.target.value })} placeholder={"Filter by Phone, TLC"} />
                              <InputGroupAddon addonType="append">
                                  <InputGroupText><i className="fa fa-search" aria-hidden="true"></i></InputGroupText>
                              </InputGroupAddon>
                          </InputGroup>
                        </>}
                      </Col>
                      <Col>
                        {activePath === "Tickets" && <>
                          <Label>Filter By Status</Label>
                          <InputGroup>
                              <Input type="select" value={filters.status} onChange={(e) => setFilters({ ...filters, status: e.target.value })}>
                                  <option value="">-- Filter by Status --</option>
                                  {["waiting", "assigned", "completed", "removed"]?.map(item => <option value={item}>{item}</option>)}
                              </Input>
                              <InputGroupAddon addonType="append">
                                  <InputGroupText><i className="fa fa-hourglass-start" aria-hidden="true"></i></InputGroupText>
                              </InputGroupAddon>
                          </InputGroup>
                        </>}
                      </Col>
                      <Col>
                          <Label>Filter By Visiting Reasons</Label>
                          <InputGroup>
                              <Input type="select" value={filters.reason} onChange={(e) => setFilters({ ...filters, reason: e.target.value })}>
                                  <option value="">-- All Reasons --</option>
                                  {allDepartments.map(reason => <option key={reason.firebaseId} value={reason.name}>{reason.name}</option>)}
                              </Input>
                              <InputGroupAddon addonType="append">
                                  <InputGroupText><i className="fa fa-building-o" aria-hidden="true"></i></InputGroupText>
                              </InputGroupAddon>
                          </InputGroup>
                      </Col>
                      <Col>
                          <Label>Filter By Agent</Label>
                          <InputGroup>
                              <Input type="select" value={filters.agent} onChange={(e) => setFilters({ ...filters, agent: e.target.value })}>
                                  <option value="">-- All Agents --</option>
                                  {allAgents.map(agent => <option key={agent.userId} value={agent.userPk}>{agent.username}</option>)}
                              </Input>
                              <InputGroupAddon addonType="append">
                                  <InputGroupText><i className="fa fa-user-o" aria-hidden="true"></i></InputGroupText>
                              </InputGroupAddon>
                          </InputGroup>  
                      </Col>
                  </Row>}
                  <Route exact path="/:tenant/:branch/admin">
                    <Dashboard filteredAgents={filteredAgents} filteredDrivers={filteredDrivers} allLanguages={allLanguages} allDepartments={allDepartments} filters={filters} setActivePath={setActivePath} />
                  </Route>
                  <Route path="/:tenant/:branch/admin/tickets">
                    <Tickets allAgents={allAgents} filteredTickets={filteredDrivers} filters={filters} setActivePath={setActivePath} currentUser={currentUser} refetchTickets={loadTickets} allBranches={allBranches} />
                  </Route>
                  <Route path="/:tenant/:branch/admin/agents">
                    <Agents allAgents={allAgents} activeDrivers={activeDrivers} setActivePath={setActivePath} />
                  </Route>
                  <Route path="/:tenant/:branch/admin/languages">
                    <Languages languages={allLanguages} loadLanguages={loadLanguages} setActivePath={setActivePath} />
                  </Route>
                  <Route path="/:tenant/:branch/admin/locations">
                    <Locations locations={allLocations.filter(loc => loc.customBranchId === branch)} loadLocations={loadLocations} setActivePath={setActivePath} allBranches={allBranches} />
                  </Route>
                  <Route path="/:tenant/:branch/admin/branches">
                    <Branches branches={allBranches} loadBranches={loadBranches} setActivePath={setActivePath} />
                  </Route>
                  <Route path="/:tenant/:branch/admin/reasons">
                    <Departments departments={allDepartments} loadDepartments={loadDepartments} setActivePath={setActivePath} />
                  </Route>
                  <Route path="/:tenant/:branch/admin/settings">
                    <Settings setActivePath={setActivePath} />
                  </Route>
              </div>
          </Col>
      </Row>
    </Switch>
  </>)
}

export default Admin
