import React, {useState, useEffect} from "react"
import { useParams, Link } from 'react-router-dom'
import { Container, Row, Col} from 'reactstrap'
import Header from "../Header/Header"
import "./Home.css"

export default function Home(){
    const [branch, setBranch] = useState(Object.values(useParams())[1])
    const [tenant, setTenant] = useState(Object.values(useParams())[0])

    return (
        <>
            <Container fluid className="vms-checkin-container">
                <Row>
                    <Col className="p-0">
                        <Header/>
                    </Col>
                </Row>
                <div className="vms-checkin-wrapper">
                    <h2 className="text-center">Welcome to <strong>VGM</strong> </h2>
                        <Row>
                            <Col lg={4} md={4}>
                                <Link to={`/${tenant}/${branch}/checkin`} >
                                    <div className="box" >
                                        <i className="fa fa-check-circle fa-5x"></i>
                                        <h4>Checkin</h4>
                                    </div>
                                </Link>
                            </Col>
                            <Col lg={4} md={4}>
                                <Link to={`/${tenant}/${branch}/admin`} >
                                    <div className="box">
                                        <i className="fa fa-user fa-5x"></i>
                                        <h4>Admin Panel</h4>
                                    </div>
                                </Link>
                            </Col>
                            <Col lg={4} md={4}>
                                <Link to={`/${tenant}/${branch}/queue`} >
                                    <div className="box">
                                        <i className="fa fa-list fa-5x"></i>
                                        <h4>Drivers Queue</h4>
                                    </div>
                                </Link>
                            </Col>
                        </Row>
                    
                </div>
            </Container>
        </>
    )
}