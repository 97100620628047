import {useEffect, useState} from 'react'
import { Row, Col, Table, InputGroup, Input, InputGroupAddon, InputGroupText, Button, Badge, UncontrolledPopover, PopoverBody, PopoverHeader } from 'reactstrap';
import { collection, serverTimestamp, addDoc, updateDoc, doc, where } from "firebase/firestore";
import { db, addPrefix } from '../firebase';
import moment from 'moment';

export default function Branches({branches, loadBranches, setActivePath}) {
    const [allBranches, setAllBranches] = useState(branches)
    const [filters, setFilters] = useState({status:""})
    const [branchName, setBranchName] = useState("")
    const [branchId, setBranchId] = useState("")
    const [error, setError] = useState("")

    const updateBranchStatus = async(branchId, value) => {
        try {
          await updateDoc(doc(db, addPrefix("branches"), String(branchId)), {
            "status" : value,
          });
          loadBranches()
          setError("")
        } catch (err) {
          setError("Error: Unable to update branch in database!")
        }
    }

    const addNewBranch = async() => {
        try {
            if(!branchName  || !branchId){
                setError("Error: Enter required fields!")
                return
            }
            await addDoc(collection(db, addPrefix("branches")), {
                name: branchName,
                status: "active",
                dateAdded: serverTimestamp(),
                customId: branchId
            });
            loadBranches()
            setError("")
        } catch (err) {
            setError("Error: Unable to add branch in database!")
        }
    }

    useEffect(() => { 
        let filteredBranches = branches;
        if(filters.status) filteredBranches = filteredBranches.filter(branch => branch.status == filters.status);
        setAllBranches(filteredBranches)
    }, [filters])

    useEffect(()=>{
        setActivePath("Branches")
    }, [])

    return (
        <Row className="no-gutters p-5">
            <Col>
                <Row className="vms-filters-wrapper no-gutters">
                    <Col xs={{size:3}}>
                        <Button onClick={()=>setBranchName("")} id="addBranchPopover"> <i className="fa fa-plus" aria-hidden="true" />&nbsp;&nbsp;Add New</Button>
                        <UncontrolledPopover   placement="right" target="addBranchPopover">
                            <PopoverHeader>Add New Branch</PopoverHeader>
                            <PopoverBody>
                                <Row>
                                    <Col>
                                        <InputGroup>
                                            <Input value={branchName} placeholder="Branch Name" onChange={(e)=>setBranchName(e.target.value)} />
                                        </InputGroup>
                                    </Col>
                                    <Col>
                                        <InputGroup>
                                            <Input value={branchId} placeholder="Branch Id" onChange={(e)=>setBranchId(e.target.value)} />
                                        </InputGroup>
                                    </Col>
                                </Row>
                                <Button color="primary" className="my-3 float-right" onClick={()=>addNewBranch()}><i className="fa fa-plus" aria-hidden="true" />&nbsp;&nbsp;Add Branch</Button>
                            </PopoverBody>
                        </UncontrolledPopover>
                    </Col>
                    <Col xs={{size:3, offset:6}}>
                        <InputGroup>
                            <Input type="select" value={filters.status} onChange={(e)=>setFilters({...filters, status:e.target.value})}>
                                <option value="">-- Filter by Status --</option>
                                {["active", "inactive"]?.map(item=><option value={item}>{item}</option>)}
                            </Input>
                            <InputGroupAddon addonType="append">
                                <InputGroupText><i className="fa fa-search" aria-hidden="true"></i></InputGroupText>
                            </InputGroupAddon>
                        </InputGroup>
                    </Col>
                </Row>
                <Row className="no-gutters">
                    {error && <Badge color="danger" className="p-3">{error}</Badge>}
                    <Col className="vms-table-wrapper">
                        <Table responsive className="vms-table vms-table-queue">
                            <thead>
                                <tr>
                                    <th>Name</th>
                                    <th>Custom Id</th>
                                    <th>Is Active</th>
                                    <th>Date Added</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {allBranches?.map(branch=><tr className="text-capitalize">
                                    <td><i className="fa fa-circle" aria-hidden="true" style={{color:branch.status === "active" ? "green" : "red"}} />&nbsp;&nbsp;{branch.name}</td>
                                    <td>{branch.customId} </td>
                                    <td>{branch.status} </td>
                                    <td>{branch.dateAdded?moment(branch.dateAdded.toDate()).format('lll'):"--"} </td>
                                    <td> <Button color={branch.status === "active" ? "primary" : "secondary"} onClick={()=>updateBranchStatus(branch.firebaseId, branch.status==="active" ? "inactive" : "active", false)}>Make Branch {branch.status === "active" ? "Inactive" : "Active"}</Button> </td>
                                </tr>)}
                                {allBranches?.length===0 && <tr><td colSpan={11}>No Branch Found!</td></tr>}
                            </tbody>
                        </Table>
                    </Col>
                </Row>
                <Row className="no-gutters pt-5">
                    <Col xs={12}>
                        <i className="fa fa-circle" aria-hidden="true" style={{color: "red"}} /> <b>&nbsp;&nbsp;Inactive</b>
                    </Col>
                    <Col xs={12}>
                        <i className="fa fa-circle" aria-hidden="true" style={{color: "green"}} /> <b>&nbsp;&nbsp;Active</b>
                    </Col>
                </Row>
            </Col>
        </Row>
    )
}
