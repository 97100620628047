
const getTenantName = (tenantName) => {
    let tenantsList = ["buggy", "ucars", "ft","mnave"]
    return tenantsList.includes(tenantName) ? tenantName : "buggy"
}

const getBaseURL = () => {
    let tenant = getTenantName(window.location.href.split("/")[3])
    return window.location.hostname.includes("staging") || window.location.hostname === "localhost"  ? `https://staging.${process.env.REACT_APP_BUGGY_BASE_URL}` : `https://${tenant}.${process.env.REACT_APP_BUGGY_BASE_URL}`
}

export { getBaseURL, getTenantName }