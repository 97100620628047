import { useState, useEffect } from 'react'
import { BrowserRouter as Router, Switch, Route } from "react-router-dom"
import { useQuery } from '@apollo/client'
import { collection, getDocs, query, where, updateDoc, doc, onSnapshot, orderBy } from "firebase/firestore"
import { db, addPrefix } from './firebase'
import Admin from './Admin/Admin'
import Agent from './Agent/Agent'
import Checkin from './Checkin/Checkin'
import Login from './Login/Login'
import gql from "graphql-tag"
import ErrorModal from './Components/ErrorModal'
import QueueContainer from './Agent/QueueContainer'
import { app } from './firebase'
import { getAuth, signInAnonymously } from 'firebase/auth'
import Home from './Home/Home'
import { getTenantName } from './functions'
import { DeviceProvider } from './Context/DeviceContext'

const USER_QUERY = gql`query CurrentUser{
  currentUser{
    id
    username
    email
    groupNames
  }  
}`


function App() {
  const [currentUser, setCurrentUser] = useState({ id: null, pk: null, token: localStorage.getItem('buggy_vms_user'), groupNames: null, username: null, status: null, email: null })
  const [allAgents, setAllAgents] = useState([])
  const [activeDrivers, setActiveDrivers] = useState([])
  const [newDrivers, setNewDrivers] = useState([])
  const [allLocations, setAllLocations] = useState([])
  const [allLanguages, setAllLanguages] = useState([])
  const [allDepartments, setAllDepartments] = useState([])
  const [allBranches, setAllBranches] = useState([])
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)

  const auth = getAuth(app)

  useQuery(USER_QUERY, {
    skip: !currentUser.id || !currentUser.pk || !currentUser.token,
    onError: (err) => { setLoading(false); setError(err) },
    onCompleted: async (response) => {
      if (response && response.currentUser && response.currentUser.groupNames) {
        setCurrentUser({ ...currentUser, groupNames: response.currentUser.groupNames, username: response.currentUser.username, email: response.currentUser.email })
        setLoading(false)
      }
    }
  })

  const handleLogout = async () => {
    try {
      if (currentUser.pk) {
        if (activeDrivers.filter(driver => driver.agent == currentUser.pk && driver.status == "assigned").length > 0) {
          setError('Error: Please complete the assigned task in order to logout!')
          return
        }

        await updateDoc(doc(db, addPrefix("users"), String(currentUser.pk)), {
          status: "inactive",
          location: null
        })
      }
      handleLogout()
    } catch (err) {
      setError("Error: Unable to logout user!")
    }

    await localStorage.removeItem('buggy_vms_user')
    window.location.reload(false)
  }

  const getUser = async () => {
    setLoading(true)
    if (currentUser.token) {
      const querySnapshot = await getDocs(query(collection(db, addPrefix("users")), where("token", "==", currentUser.token)))
      let tmp = true
      await querySnapshot?.forEach((doc) => {
        tmp = false
        let data = doc.data()
        setCurrentUser({ ...currentUser, id: data.userId, pk: data.userPk, status: data.status, location: data.location, isAgent: data.isAgent, language: data.language })
      })
      if (tmp) handleLogout()
    } else {
      setCurrentUser({ id: null, pk: null, token: null, groupNames: null, username: null, status: null })
      setLoading(false)
    }
  }

  const signinAnonymously = () => {
    if (auth.currentUser == null || localStorage.getItem('buggy_vms_firebase_user')) {
      signInAnonymously(auth).then((userCredential) => {
        const user = userCredential.user
        localStorage.setItem('buggy_vms_firebase_user', user.uid)
      }).catch((error) => {
        console.error("Error signing in anonymously:", error)
      })
    } else {
      console.log("user already logged in")
    }
  }

  //To get all languages
  const loadLanguages = async () => {
    const querySnapshot = await getDocs(query(collection(db, addPrefix("languages"))))
    setAllLanguages(querySnapshot.docs.map((doc) => ({ ...doc.data(), firebaseId: doc.id })))
  }
  //To get all locations
  const loadLocations = async () => {
    const querySnapshot = await getDocs(query(collection(db, addPrefix("locations"))))
    setAllLocations(querySnapshot.docs.map((doc) => ({ ...doc.data(), firebaseId: doc.id })))
  }
  //To get all Department
  const loadDepartments = async () => {
    const querySnapshot = await getDocs(query(collection(db, addPrefix("departments"))))
    setAllDepartments(querySnapshot.docs.map((doc) => ({ ...doc.data(), firebaseId: doc.id })))
  }

  const loadBranches = async () => {
    const querySnapshot = await getDocs(query(collection(db, addPrefix("branches"))))
    const tenant = getTenantName(window.location.href.split("/")[3])
    setAllBranches(querySnapshot.docs.map((doc) => ({ ...doc.data(), firebaseId: doc.id })).filter(doc => doc.tenant === tenant))
  }

  useEffect(() => {
    signinAnonymously()
    loadLanguages()
    loadLocations()
    loadDepartments()
    loadBranches()
  }, [])

  useEffect(() => {
    getUser()
  }, [currentUser.token])

  //To get all users/agents
  useEffect(() => {
    const unsubscribe = onSnapshot(query(collection(db, addPrefix("users"))), (querySnapshot) => {
      setAllAgents(querySnapshot.docs.map(doc => ({ ...doc.data(), firebaseId: doc.id })))
    })
    return () => unsubscribe()
  }, [])

  //To get all drivers
  useEffect(() => {
    let customBranchId = window.location.href.split("/").filter(i=>i!="")[3]
    if(customBranchId){
      const unsubscribe = onSnapshot(query(collection(db, addPrefix("tickets")), where("status", "in", ["waiting", "assigned"]), where("customBranchId", "==", customBranchId), orderBy("dateAdded", "asc")), (querySnapshot) => {
        let data = null, newTickets = []
        querySnapshot.docChanges().forEach((change) => {
          data = change.doc.data()
          if (change.type === "added" && data.status == "assigned")
            newTickets.push({ ...data, firebaseId: change.doc.id })
          setNewDrivers(newTickets)
        })
        setNewDrivers(newTickets)
        setActiveDrivers(querySnapshot.docs.map(doc => ({ ...doc.data(), firebaseId: doc.id })))
      })
      return () => unsubscribe()
    }
  }, [])


  return (
    <DeviceProvider>
      <div className="app">
        {error && error != "" && <ErrorModal open={!!error} handleClose={() => setError("")} message={error} />}
        <Router>
          <Switch>
            <Route exact path="/:tenant/:branch/checkin/:QR?">
              <Checkin activeDrivers={activeDrivers} />
            </Route>
            <Route path="/:tenant/:branch/admin">
              {currentUser && currentUser.id && currentUser.pk && currentUser.token && currentUser.groupNames && currentUser.groupNames.includes("VMS-Admin") && !currentUser.isAgent ?
                <Admin currentUser={currentUser} allAgents={allAgents} activeDrivers={activeDrivers} allLocations={allLocations} allLanguages={allLanguages} allDepartments={allDepartments} loadLocations={loadLocations} loadLanguages={loadLanguages} loadDepartments={loadDepartments} setCurrentUser={setCurrentUser} handleLogout={handleLogout} allBranches={allBranches} loadBranches={loadBranches} /> :
                currentUser && currentUser.id && currentUser.pk && currentUser.token && currentUser.groupNames && currentUser.isAgent ?
                  <Agent currentUser={currentUser} allAgents={allAgents} locations={allLocations} languages={allLanguages} activeDrivers={activeDrivers} newDrivers={newDrivers} setCurrentUser={setCurrentUser} handleLogout={handleLogout} /> :
                  <Login />
              }
            </Route>
            <Route path="/:tenant/:branch/queue">
              <QueueContainer allAgents={allAgents} activeDrivers={activeDrivers} newDrivers={newDrivers} locations={allLocations} />
            </Route>
            <Route path="/:tenant/:branch">
              <Home />
            </Route>
            <Route path="*">
              <Home />
            </Route>
          </Switch>
        </Router>
      </div>
    </DeviceProvider>
  )
}

export default App
