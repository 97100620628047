import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { ApolloProvider } from "@apollo/client"
import { client } from './ApolloClient';
import 'font-awesome/css/font-awesome.min.css'
import 'bootstrap/dist/css/bootstrap.css'
import './index.css'

ReactDOM.render(
  <ApolloProvider client={client}>
    <App />
  </ApolloProvider>,
  document.getElementById('root')
)
// firebase init 

// firebase target:apply hosting buggy-checkin buggy-checkin
// firebase target:apply hosting ucars-checkin ucars-checkin
// firebase deploy --only hosting:buggy-checkin
// firebase deploy --only hosting:ucars-checkin
// firebase deploy
// firebase hosting:sites:create ucars-checkin (first/one time only)